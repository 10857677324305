import Vue from 'vue';
import shortid from 'shortid';
import { firebaseApp ,externalUsersCollection ,auth,customersCollection} from '././firebase';
import firebase from 'firebase/app';
import storeHelper from './mixins/storeHelper';
import { mapState } from 'vuex';
import store from './store.js';
import router from './router';
const crypto = require('crypto-js');

export async function clioLogin(){
    const identity_state = shortid.generate();
    document.cookie= identity_state;
    const params = {
        "response_type": "code",
        "client_id": process.env.VUE_APP_CLIO_CLIENT_ID,
        "redirect_uri": `${window.location.origin}/identity_callback`,
        // scope: "openid",
        "state": identity_state,
        "redirect_on_decline":true
        // client_id: process.env.VUE_APP_CLIO_CLIENT_ID,
      }
      console.log("id:", 'kkkkkkk-')
    // let encodedVal = encodeURI(`&redirect_uri=${params.redirectUri}&state=${params.state}`)
    try{
      // let url = `https://account.clio.com/oauth2/auth?response_type=${params.response_type}&client_id=${params.client_id}&scope=${params.scope}&redirect_uri=${params.redirect_uri}&state=${params.state}`
      let url=`https://app.clio.com/oauth/authorize?response_type=${params.response_type}&client_id=${params.client_id}&redirect_uri=${params.redirect_uri}&state=${params.state}&redirect_on_decline=true`
      // console.log("URL:", url)
      window.location.href=url
    }catch(err){
      console.log("clioLogin error:",err)
    }
}


export async function performInitialClioLoginOperation(code){
    const requestToCall = firebaseApp.functions('northamerica-northeast1').httpsCallable('clioApis-fetchAccessTokenFromClio');
    const body = {
      grant_type: "authorization_code",
      client_id: process.env.VUE_APP_CLIO_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIO_CLIENT_SECRET,
      redirect_uri: `${window.location.origin}/identity_callback`,
      redirect_on_decline:true,
      code:code,
    };
    return new Promise((resolve,reject)=>{
    try{
    requestToCall(body)
      .then(async (linkResponse) => {
        // console.log("linkResponse--:",linkResponse);
        if(linkResponse && linkResponse.data && linkResponse.data.success){
          localStorage.setItem('cliotoken',linkResponse.data.data)
          const tokenResponse = JSON.parse(linkResponse.data.data);
          // console.log("Token Response:", tokenResponse);
          if(tokenResponse && tokenResponse.access_token){
              const clioUserDetails = await clioGetUserDetails(tokenResponse.access_token).catch((err)=>{``
                console.error("clioUserDetails Err:", err);
                return reject(err);
              })
              console.log("clioUserDetails Response ->", clioUserDetails);
              if(clioUserDetails.data && clioUserDetails.data.email){
                /////
                if(!isBothEmailsSame(clioUserDetails.data.email))
                  {
                    console.log('=++++++++++')
                    const vue = new Vue();  
                    vue.$bvToast.toast("The email associated with your login provider and Clio account is different. Please login with same email ids to continue", {
                      title: 'Email ids mismatch',
                      variant: 'danger',
                      'no-auto-hide':true
                    });
                    store.dispatch('logout').then((resp)=>{
                      return router.push({ path: '/auth' })
                    });
                    return;
                  }
                localStorage.setItem('clioUser', JSON.stringify(clioUserDetails.data));
                console.log("clioUserDetails -------->", clioUserDetails);
                const existingUserDetails = await userExistsWithEmailProvided(clioUserDetails.data.email);
                console.log("userExists -------->", existingUserDetails);
                if(existingUserDetails){
                  const hasPasswordProvider = existingUserDetails.providerData.some(provider => provider.providerId === 'password');
                  const hasGoogleProvider = existingUserDetails.providerData.some(provider => provider.providerId === 'google.com');

                  if (!hasPasswordProvider && hasGoogleProvider && store.state.loginProvider && store.state.loginProvider =='CLIO') {
                    resolve({
                      state : 'SHOW_GOOGLE_POPUP',
                      emailId:clioUserDetails.data.email
                    });
                  } else {
                  loginToDiviiFromClio(clioUserDetails.data.email).then((res)=>{
                    if(res){
                      let response = {
                        state : res.state,
                        emailId:clioUserDetails.data.email
                      }
                      resolve(response)
                    } else {
                      resolve(true)
                    }
                  })
                 }
                } else {
                  resolve({
                    state : 'SHOW_SIGN_UP',
                    emailId:clioUserDetails.data.email
                  });
                  // COMMENTED FOR PERMISSION MODULE
                  // const signUpNewUserFromClioRequest = firebaseApp.functions('northamerica-northeast1').httpsCallable('clioApis-signUpNewUserFromClio');
                  // const body = {
                  //   email: clioUserDetails.data.email,
                  // };

                  // try{
                  //   signUpNewUserFromClioRequest(body).then((resp)=>{
                  //     console.log("signUpNewUserFromClioRequest resp--:",resp);
                  //     loginToDiviiFromClio(clioUserDetails.data.email).then((res)=>{
                  //       if(res && res.state){
                  //         resolve(res.state)
                  //       } else {
                  //         resolve()
                  //       }
                  //     })
                  //   })
                  //   .catch((err)=>{
                  //     console.log("signUpNewUserFromClioRequest error--:",err);
                  //   })
                  // } catch(err){
                  //   console.error("err--:",err);
                  // }
                }
              }
          }
        }
      }).catch((err)=>{
        console.error("performInitialClioLoginOperation requestToCall error->",err)
        return reject(err);
      })
    }catch(err){
      console.error("performInitialClioLoginOperation error:",err)
      return reject(err);
    }
   })
}

const loginToDiviiFromClio = async (email)=>{
  console.log(`loginToDiviiFromClio start `,email);
  // let externalUserDetails = await externalUsersCollection.where('externalEmail', '==', email).get();
  // console.log(`loginToDiviiFromClio externalUserDetails `,externalUserDetails);
  return new Promise(async (resolve,reject) =>{
  
  // if(externalUserDetails && externalUserDetails.docs && externalUserDetails.docs[0] && externalUserDetails.docs[0].data()){
  //   const cipher = process.env.VUE_APP_CLIO_EXTRA_KEY;
  //   const convertedString = crypto.AES.decrypt(externalUserDetails.docs[0].data().additionalValue, cipher);
  //   console.log(`convertedString ==>`, convertedString.toString(crypto.enc.Utf8));
  //   if(convertedString){
  //     let updatedEmail =  externalUserDetails.docs[0].data().email;
  //     let pwd =  convertedString.toString(crypto.enc.Utf8);
  //     console.log(`email ==>`, updatedEmail,`pwd ==>`, pwd);

  //     const userLoggedIn = auth.currentUser;
  //     if(userLoggedIn){
  //       router.push({ name: 'home' });
  //     } else {
  //     const userSnap = await auth.signInWithEmailAndPassword(updatedEmail,pwd);
  //     // store.commit('setLoginProvider', 'CLIO')
  //     setLoginProviderAndClioFetchValuesToStorage();
  //     store.dispatch('login', userSnap.user).then(() => {
  //       // store.commit('setLoading', false);
  //       console.log(`User ${userSnap.user.email} logged in`);
  //       router.push({ name: 'home' }); 
  //       resolve()
  //     });
  //    }
  //   }
  // } else {
    setLoginProviderAndClioFetchValuesToStorage();
    const userLoggedIn = auth.currentUser;
    console.log('userLoggedIn==>',userLoggedIn);
    let userAlreadySignedUp = await getCustomerUsingEmail(email)
    console.log('userAlreadySignedUp-->',userAlreadySignedUp);
    
      
    if(userLoggedIn){
      router.push({ name: 'home' });
      resolve()
    } else {
      if(Object.keys(userAlreadySignedUp).length && userAlreadySignedUp.roleLevel){
        resolve({state : 'SHOW_LOGIN'})
      } else{
        resolve({state : 'SHOW_SIGN_UP'})
      }
    }
  // }  
 })
}

const clioGetUserDetails = (token)=>{
  const getUserAPI = firebaseApp.functions('northamerica-northeast1').httpsCallable('clioApis-clioGetUserDetails');
  const header={
    authorization: token
  }
  return new Promise(async (resolve, reject) => {
    const linkResponse = await getUserAPI(header).catch((err)=>{
      console.error("clioUserDetails err:", err);
      return err;
    });
    // console.log("linkResponse--:",linkResponse);
    if(linkResponse && linkResponse.data && linkResponse.data.success){
      const userData = JSON.parse(linkResponse.data.data);
      // console.log("userData Response:", userData);
      resolve(userData)
    } else{
      let errorMsg  = (linkResponse & linkResponse.data && !linkResponse.data.success) ? linkResponse.data.error : 'Error in clioGetUserDetails' 
      reject(errorMsg)
    }

  });
}

const userExistsWithEmailProvided = (email)=>{
  return new Promise(async (resolve, reject) => {
    const userExistsUsingEmail = firebaseApp.functions('northamerica-northeast1').httpsCallable('clioApis-checkUserExistsUsingEmailForClio');
    try{
      userExistsUsingEmail(email).then((res)=>{
        console.log('userExistsUsingEmail res>',res)
        if(res && res.data){
          if(res.data.success)
            resolve(res.data.data)
          else
            resolve(false)
        } else {
          resolve(false)
        }
      })
    }catch(err){
      console.log('userExistsWithEmailProvided err-->',err)
    }
  });
}

export const setLoginProviderAndClioFetchValuesToStorage = () => {
  const redirectRouteParams = localStorage.getItem('redirectRouteParams')
  if(redirectRouteParams && JSON.parse(redirectRouteParams)){
    let redirectRouteParamsObj = JSON.parse(redirectRouteParams);
    if(redirectRouteParamsObj.authProvider){
      store.commit('setLoginProvider', redirectRouteParamsObj.authProvider);
      if(redirectRouteParamsObj.clioFetch && redirectRouteParamsObj.clioFetch == 'true')
        localStorage.setItem('isClioFetch', true);
      else
        localStorage.removeItem('isClioFetch');
    }
  }
}

const isBothEmailsSame = (clioEmail)=>{
  console.log('>>>>>')
  if(localStorage.getItem('loggedInEmailID')){
    console.log('<><><><><><>')
    if(localStorage.getItem('loggedInEmailID') != clioEmail){
      console.log('{}{}{}{}{}}')
      return false;
    }
  }
  return true;
}

const getCustomerUsingEmail = async(email)=>{
  return new Promise(async resolve => {
    const customers  = await customersCollection.where("email", "==", email).get();
      const data = customers.docs.map((doc) => doc.data());
      data && data[0] ? resolve(data[0]) : resolve({});
  });
}